



































































































import axios from '@/utils/axios';
import { muscles } from '@/utils/constants';
import { IPosition } from '@/utils/interfaces';
import { CreatePositionDTO } from '@/utils/models';
import { Vue, Component } from 'vue-property-decorator';
@Component
export default class AddPosition extends Vue {
  public submitting = false;
  public muscles = muscles;
  public newPosition: IPosition = {
    _id: '',
    thumbnail: '',
    primaryMusclesMan: [],
    secondaryMusclesMan: [],
    primaryMusclesWoman: [],
    secondaryMusclesWoman: [],
    level: 1,
    intimacy: 1
  };

  public async submit(): Promise<void> {
    const {
      thumbnail,
      primaryMusclesMan,
      secondaryMusclesMan,
      primaryMusclesWoman,
      secondaryMusclesWoman,
      level,
      intimacy
    } = this.newPosition;

    if (thumbnail.length === 0) {
      alert('Please insert a thumbnail URL');
      return;
    }
    if (primaryMusclesMan.length === 0) {
      alert('Please insert a primary muscle for men');
      return;
    }
    if (secondaryMusclesMan.length === 0) {
      alert('Please insert a secondary muscle for men');
      return;
    }
    if (primaryMusclesWoman.length === 0) {
      alert('Please insert a primary muscle for women');
      return;
    }
    if (secondaryMusclesWoman.length === 0) {
      alert('Please insert a secondary muscle for women');
      return;
    }

    this.submitting = true;

    const { data } = await axios.post('position/create', {
      thumbnail: thumbnail,
      primaryMusclesMan: primaryMusclesMan,
      primaryMusclesWoman: primaryMusclesWoman,
      secondaryMusclesMan: secondaryMusclesMan,
      secondaryMusclesWoman: secondaryMusclesWoman,
      level: level,
      intimacy: intimacy
    } as CreatePositionDTO);

    if (data && data._id) {
      this.$store.commit('addPosition', data);
      this.$router.push({ name: 'positions-detail', params: { id: data._id } });
    } else {
      this.$router.back();
    }
  }
}
